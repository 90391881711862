html, body {
  height: 100%;
  min-height: -webkit-fill-available;
}

#root, .app {
  height: 100%;
}

.App-logo {
  height: 8rem;
  pointer-events: none;
}

.App-header {
  background-color: hsl(220, 13%, 10%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}



